.Info {
  grid-area: 3 / 2 / -2 / -2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;

  p, h1 {
    width: 100%;
    max-width: 30rem;
    margin: 0 auto 1rem;
  }

  h1 {
    font-size: inherit;
    font-weight: inherit;
  }
}

@media screen and (min-width: 768px) {
.Info {

}
}
