.Projects {
  grid-area: 3 / 2 / -2 / -2;
  width: 100%;
  height: 100%;

  display: inline-grid;
  grid-template-columns: repeat(auto-fill, minmax(240px,1fr));
  grid-auto-rows: 1fr;
  grid-gap: 1rem;

  .ProjectThumb {
    display: inline-flex;
    flex-flow: column nowrap;
    align-self: center;
    margin: 0;

    * {
      width: 100%;
      margin: auto;
    }

    a {
      width: 100%;
      padding: 0;
    }
    img {
      max-height: calc(100vh - 1rem - 1em - 1rem - 1em);
      height: 100%;
      object-fit: contain;
    }
    figcaption {
      display: none;
    }
  }
}

@media screen and (min-width: 768px) {
.Projects {
  grid-template-columns: repeat(5,1fr);
  grid-gap: 4rem;

  .ProjectThumb {
    grid-row: 1 / 2;

    img {
      transition: filter .5s ease;
      max-height: calc(100vh - 4rem - 3rem - 1rem - 4em);

      &:hover {
        filter: opacity(0.75);
      }
    }

    figcaption {
      display: block;
      padding: 1em 0 0;
      text-align: left;

      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }

    &:hover > figcaption {
      transition: none;
      zoom: 1;
      filter: alpha(opacity=100);
      opacity: 1;
    }
  }
}
}
