$white-space: 1rem;

.Project {
  grid-area: 3 / 2 / -2 / -2;
  width: auto;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  margin-left: -$white-space;
  margin-right: -$white-space;

  section, img {
    margin: $white-space;
    margin-top: 0;
    max-width: 30rem;
    flex: 0 1 30rem;

    &.stretch {
      width: 100%;
      flex: 100 0 100%;
      justify-content: space-between;
    }
  }

  section {
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: flex-start;

    img {
      margin: 0;
      padding: 0;
    }

    &.stretch {
      max-width: calc(100vw - 1rem - 1rem);
    }
  }

  img {
    flex: 0 1 auto;

    box-sizing: content-box;
    height: 100%;
    max-height: calc(100vh - 1rem - 1em - 1rem - 1em);
    max-width: calc(100vw - 1rem - 1rem);

    object-fit: contain;
    object-position: center;

    &.banner {
      width: 100vw;
      max-width: 100vw;
      margin-left: 0;
      margin-right: 0;
      height: auto;
      flex: 1 0 100vw;
    }
  }
}

@media screen and (min-width: 768px) {

$white-space: 4rem;

.Project {
  margin-left: -$white-space;
  margin-right: -$white-space;

  section,  img {
    margin: $white-space;
    margin-top: 0;
  }

  section {
    width: 50vw;

    &.stretch {

      max-width: calc(100vw - 4rem - 4rem);

      display: inline-grid;
      grid-auto-columns: 1fr;
      grid-gap: 4rem;
      justify-items: center;

      img, section {
        grid-row: 1 / 2;
        width: 100%;
        max-width: 30rem;
        margin: 0;

        height: auto;
        object-fit: cover;
        object-position: bottom;

        &:first-child {
          justify-self: start;
        }

        &:last-child {
          justify-self: end;
        }
      }
    }
  }

  img {
    margin-bottom: $white-space;
    max-height: calc(100vh - 4rem - 3rem - 3rem - 4rem);
    max-width: calc(100vw - 4rem - 4rem);

    &.banner {
      object-fit: cover;
      object-position: bottom;
    }

    &.stretch {
      object-fit: cover;
    }
  }
}
}
