$white-space: 1rem;

.ProjectCarousel {
  width: 100%;


  section {
    max-width: 30rem;
    margin: 0 1rem 1rem;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  img {
    width: 100%;
    margin: 0 $white-space;
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
  }
  .carousel-item {

  }
}

@media screen and (min-width: 768px) {

  $white-space: 4rem;

  .ProjectCarousel {

    section {
      margin: 0 auto;
    }

    img {
      margin: 0 $white-space;
    }

  }

}
