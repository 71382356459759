$radius: 2px;

.Contact {
  grid-area: 3 / 2 / -2 / -2;
  width: 100%;
  height: calc(100vh - 1rem - 1rem - 1rem - 1em);
  display: flex;
  flex-flow: column wrap;
  justify-content: center;

  & > div {
    width: fit-content;
    margin: 0 auto;
  }

  address, section, form {
    margin-bottom: 3rem;
  }

  p {
    font-style: normal;
    max-width: 30rem;
    margin: 0 auto 1rem;
  }

  a {
    text-decoration: none;

    &.active, &:hover, &.important {
      text-decoration: underline;
    }
  }

  .social {
    :not(:first-child) {
      margin-left: 1rem;
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  form {
    display: inline-grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    align-items: end;

    width: 100%;

    label {
      color: inherit;
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }

    input {
      grid-row: 2 / 3;
      height: 1.5rem;
      border-radius: $radius;

      &.hidden {
        display: block;
        position: absolute;
        left: -5000px;
      }

      &.email {
        grid-column: 1 / 2;
        border: 1px solid #aaa;
        width: auto;
      }
      &.button {
        grid-column: -2 / -1;
        border: 0px solid #aaa;
        background-color: #eee;
        color: inherit;
        font-size: inherit;
        transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
        cursor: pointer;

        &:hover, &:focus {
          background-color: #666;
          color: #fff;
        }
      }
    }

    #mce-responses {
      grid-row: -2 / -1;
    }
  }
}

@media screen and (min-width: 768px) {
.Contact {
  height: calc(100vh - 4rem - 3rem - 1rem - 4em);
}
}
