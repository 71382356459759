@import "../node_modules/bootstrap/scss/bootstrap";

@mixin gridtemplate($white-space) {
  grid-template-rows: $white-space min-content minmax(min-content,auto) $white-space;
  grid-template-columns: $white-space auto $white-space;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;

  font-family: NeueHaasUnicaPro-Regular, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 0.885rem;
  font-display: swap;
  line-height: 1.5;

  color: #212529;
}

.info {
  color: #17a2b8;
}
.danger {
  color: #dc3545;
}
.success {
  color: #28a745;
}

.hidden {
  display: none;
}

a {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

h1 {
  margin-top: 0;
  width: 100%;
}

.App {
  display: grid;
  @include gridtemplate(1rem);
  width: 100vw;

  .Loading {
    grid-area: 3 / 2 / -2 / -2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
.App {
  @include gridtemplate(4rem);
  height: 100vh;
}
}

/**
* Fonts
*/
/**
 * @license
 * MyFonts Webfont Build ID 3415456, 2017-07-02T06:59:41-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: NeueHaasUnicaPro-Regular by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/regular/
 * Copyright: Copyright &#x00A9; 2015 Monotype Imaging Inc.  All rights reserved.
 * Licensed pageviews: 250,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3415456
 *
 * © 2017 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/341da0");


@font-face {
  font-family: 'NeueHaasUnicaPro-Regular';
  src: url('./fonts/341DA0_0_0.eot');
  src: url('./fonts/341DA0_0_0.eot?#iefix') format('embedded-opentype'),
  url('./fonts/341DA0_0_0.woff2') format('woff2'),
  url('./fonts/341DA0_0_0.woff') format('woff'),
  url('./fonts/341DA0_0_0.ttf') format('truetype');
}
